<template>
  <div class="auth-box">
    <div class="auth-list">
      <span class="span-title">权限列表</span>
      <!-- <van-button type="primary" size="small" @click="show = true" style="min-width: 80px"
        >新建</van-button
      > -->
      <div class="collapse-box">
        <van-collapse v-model="activeNames" class="auth-span">
        <van-collapse-item
          v-for="item in authList"
          :name="item.name"
          :data-name="item.name"
          :key="item.id"
        >
          <template #title>
            <div style="position: relative">
              {{ item.name }}
              <div style="position: absolute; top: -5px; right: 10px; z-index: 999">
                <van-button @click.stop="editParent(item)" size="mini" type="primary">
                  <van-icon name="edit" />
                </van-button>
                <van-button @click.stop="deleteParent(item)" size="mini" type="danger">
                  <van-icon name="delete-o" />
                </van-button>
              </div>
            </div>
          </template>
          <van-collapse v-model="activeChildNames" class="auth-span child">
            <van-collapse-item
              v-for="cl in item.children"
              :title="cl.name"
              :name="cl.name"
              :data-name="cl.name"
              :key="cl.id"
            >
              <van-button
                size="small"
                type="primary"
                style="margin-right: 10px"
                @click="editParent(cl)"
                >编辑</van-button
              >
              <van-button size="small" type="danger" @click.stop="deleteParent(cl)"
                >删除</van-button
              >
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>
      </div>
    </div>
    <!-- 新建编辑弹窗 -->
    <van-dialog v-model:show="show" title="新建权限" :showConfirmButton="false">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="editAuthParams.name"
            name="name"
            label="权限名称"
            placeholder="请输入权限名称"
            :rules="[{ required: true, message: '请输入权限名称' }]"
          />
          <van-field
            v-model="editAuthParams.slug"
            name="slug"
            label="唯一标识"
            placeholder="请输入权限唯一标识"
            :rules="[{ required: true, message: '请输入权限唯一标识' }]"
          />
          <van-field
            v-model="editAuthParams.order"
            name="order"
            label="排序"
            placeholder="请输入排序（选填）"
          />
          <van-popover
            v-model:show="showParentsPopover"
            :actions="parentsActions"
            @select="onSelectParents"
          >
            <template #reference>
              <van-field
                v-model="editAuthParams.parent_id"
                name="parent_id"
                label="父级"
                disabled
                placeholder="请输入父级权限（选填）"
              />
            </template>
          </van-popover>
        </van-cell-group>
        <div style="margin: 16px; display: flex">
          <van-button
            round
            block
            type="default"
            style="margin-right: 10px"
            @click="show = false"
          >
            取消
          </van-button>
          <van-button round block type="primary" native-type="submit"> 提交 </van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import {
  Button,
  CellGroup,
  Collapse,
  CollapseItem,
  Dialog,
  Field,
  Form,
  Icon,
  Popover,
  Toast,
} from "vant";
import httpAuth from "../../../api/auth";
export default {
  name: "AuthManagement",
  components: {
    "van-collapse": Collapse,
    "van-collapse-item": CollapseItem,
    "van-button": Button,
    "van-icon": Icon,
    "van-dialog": Dialog.Component,
    "van-form": Form,
    "van-cell-group": CellGroup,
    "van-field": Field,
    "van-popover": Popover,
  },
  setup() {
    const activeNames = ref([]);
    const activeChildNames = ref([]);
    const show = ref(false);
    const showParentsPopover = ref(false);
    const showOrderPopover = ref(false);
    // 提交新建、编辑
    const onSubmit = async (values) => {
      if (!isEdit.value) {
        const res = await httpAuth.create(values);
        if (res) {
          Toast(res.msg);
          getAuthList();
          show.value = false;
        }
      } else {
        const res = await httpAuth.update(editAuthParams);
        if (res) {
          // console.log(res);
          Toast(res.msg);
          getAuthList();
          show.value = false;
        }
      }
    };
    const orderActions = ref([{ text: 0 }, { text: 1 }]);
    // 获取权限列表
    const authList = ref([]);
    const parentsActions = ref([]);
    const getAuthList = async () => {
      const res = await httpAuth.query();
      if (res.code == 200) {
        authList.value = res.data;
        const arr = res.data;
        arr.forEach((el) => {
          el.text = el.name;
        });
        parentsActions.value = arr;
      }
    };

    const editAuthParams = reactive({
      id: "",
      name: "",
      slug: "",
      parent_id: "",
      order: "",
    });
    // 修改权限
    const isEdit = ref(false);
    const editParent = (item) => {
      isEdit.value = true;
      editAuthParams.id = item.id;
      editAuthParams.name = item.name;
      editAuthParams.slug = item.slug;
      editAuthParams.parent_id = item.parent_id;
      editAuthParams.order = Number(item.order);
      show.value = true;
    };

    // 删除权限
    const deleteParent = (item) => {
      Dialog.confirm({
        title: "删除确认",
        message: "是否删除该权限",
      })
        .then(async () => {
          const res = await httpAuth.del({ ids: [item.id] });
          if (res.code == 200) {
            Toast(res.msg);
            getAuthList();
          }
        })
        .catch(() => {
          Toast("取消删除");
        });
    };

    // 选择父级
    const onSelectParents = (item) => {
      editAuthParams.parent_id = item.id;
    };

    // 选择排序
    const onSelectOrder = (item) => {
      editAuthParams.order = Number(item.text);
    };
    getAuthList();

    // 监听新建编辑弹窗关闭
    watch(show, (val) => {
      if (!val) {
        editAuthParams.id = "";
        editAuthParams.name = "";
        editAuthParams.slug = "";
        editAuthParams.parent_id = "";
        editAuthParams.order = "";
        isEdit.value = false;
      }
    });
    return {
      activeNames,
      activeChildNames,
      authList,
      show,
      editAuthParams,
      parentsActions,
      showParentsPopover,
      orderActions,
      showOrderPopover,

      onSubmit,
      editParent,
      deleteParent,
      onSelectParents,
      onSelectOrder,
    };
  },
};
</script>
<style scoped lang="less">
.auth-list {
  text-align: left;
}
.auth-span {
  span {
    padding: 10px 40px;
    border: 2px solid skyblue;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 10px;
    background-color: #f7fafb;
  }
}
.van-collapse-item {
  border-bottom: 2px solid skyblue;
}
.child .van-collapse-item {
  border: none;
}
::v-deep .auth-list .van-collapse-item__content {
  padding: 0;
  padding-left: 20px;
}
.span-box {
  display: flex;
  flex-wrap: wrap;
}
.span-title {
  display: inline-block;
  text-align: center;
  padding: 40px;
  width: 100%;
  font-size: 40px;
  font-weight: 800;
  border-bottom: 2px solid skyblue;
  box-sizing: border-box;
}
// .auth-box {
//   padding-bottom: 200px;
// }
::v-deep .van-field--disabled .van-field__label {
  color: #646566 !important;
}
::v-deep .van-popover__action {
  width: 100%;
}
:root {
  --van-popover-action-width: 100%;
}
.collapse-box {
  padding-bottom: 180px;
  overflow-y: auto;
  height: 1160px;
  box-sizing: border-box;
}
</style>
